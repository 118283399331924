<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Suppliers
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Search..."
        append-icon="fas fa-search"
        single-line
        hide-details
        clearable
        clear-icon="fas fa-times"
        @keyup="searchKeyUp()"
        @click:clear="searchKeyUp()"
      ></v-text-field>
      <v-btn
        class="ml-4"
        depressed
        color="secondary"
        @click="newClick"
        :disabled="!user.can('supplier.create')"
      >
        <v-icon
          small
          left
        >fas fa-folder-plus</v-icon>
        New
      </v-btn>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >
    
    </v-data-table>


    <v-dialog
      v-model="dialog"
      max-width="1100"
      persistent
    >
      <v-card>
        <v-toolbar color="primary" dark flat short class="px-2">
          <v-toolbar-title>Supplier</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent :disabled="!user.can('supplier.update')">

            <v-row>
              <v-col cols="6">

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.code"
                  :error-messages="errors.code"
                  label="Code"
                ></v-text-field>

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.name"
                  :error-messages="errors.name"
                  label="Name"
                ></v-text-field>

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.odoo_name"
                  :error-messages="errors.odoo_name"
                  label="Odoo Name"
                ></v-text-field>

                <v-combobox
                  outlined
                  class="mb-2"
                  v-model="model.fields_data"
                  :error-messages="errors.fields_data"
                  :items="model.fields_data"
                  label="Fields"
                  multiple
                  chips
                ></v-combobox>

              </v-col>
              <v-col cols="6">

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.field_size"
                  :error-messages="errors.field_size"
                  label="Size field"
                  hide-details
                  dense
                ></v-text-field>

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.field_brand"
                  :error-messages="errors.field_brand"
                  label="Brand field"
                  hide-details
                  dense
                ></v-text-field>

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.field_primary_color"
                  :error-messages="errors.field_primary_color"
                  label="Primary color field"
                  hide-details
                  dense
                ></v-text-field>

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.field_secondary_color"
                  :error-messages="errors.field_secondary_color"
                  label="Secondary color field"
                  hide-details
                  dense
                ></v-text-field>

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.field_tertiary_color"
                  :error-messages="errors.field_tertiary_color"
                  label="Tertiary color field"
                  hide-details
                  dense
                ></v-text-field>

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.field_quaternary_color"
                  :error-messages="errors.field_quaternary_color"
                  label="Quaternary color field"
                  hide-details
                  dense
                ></v-text-field>

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.field_weight"
                  :error-messages="errors.field_weight"
                  label="Weight field"
                  hide-details
                  dense
                ></v-text-field>

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.field_description"
                  :error-messages="errors.field_description"
                  label="Description field"
                  hide-details
                  dense
                ></v-text-field>

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.field_category"
                  :error-messages="errors.field_category"
                  label="Category field"
                  hide-details
                  dense
                ></v-text-field>

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.field_product"
                  :error-messages="errors.field_product"
                  label="Product field"
                  hide-details
                  dense
                ></v-text-field>

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.field_purchase_price"
                  :error-messages="errors.field_purchase_price"
                  label="Purchase price field"
                  hide-details
                  dense
                ></v-text-field>

                <v-text-field
                  outlined
                  class="mb-2"
                  v-model="model.field_sku"
                  :error-messages="errors.field_sku"
                  label="Sku field"
                  hide-details
                  dense
                ></v-text-field>


              </v-col>
            </v-row>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-btn
            color="warning"
            class="px-5"
            large
            outlined
            depressed
            @click="showUploadDialog()"
            :disabled="isNewRecord || !user.can('product-variant-supplier.import')"
          >Upload prices</v-btn>
          <v-btn
            color="green"
            class="px-5"
            large
            outlined
            depressed
            @click="downloadExport()"
          >Export</v-btn>
          <v-btn
            color="green"
            class="px-5"
            large
            outlined
            depressed
            @click="downloadPrices()"
          >Prices</v-btn>
          <v-btn
              icon
              @click="odooDialogRef = true"
          ><v-icon>fas fa-sync</v-icon></v-btn>
          <span
            v-if="uploadModel.message"
            class="px-5"
          >{{ uploadModel.message }}</span>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Cancel</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
            :disabled="!user.can('supplier.update')"
          >Save</v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

    <v-dialog
      v-model="uploadDialog"
      max-width="600"
      persistent
    >
      <v-card>
        <v-toolbar color="primary" dark flat short class="px-2">
          <v-toolbar-title>Upload prices</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="uploadDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form
            ref="uploadForm"
            @submit.prevent
          >
            <v-row>
              <v-col cols="10">

                <file-drop-input
                  outlined
                  v-model="file"
                  :error-messages="uploadErrors.data_url"
                  clear-icon="fas fa-times"
                  accept="text/csv"
                  label="File input"
                ></file-drop-input>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  outlined
                  v-model="uploadModel.csv_separator"
                  :error-messages="uploadErrors.csv_separator"
                  label="Separator"
                ></v-text-field>
              </v-col>
            </v-row>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="uploadDialog = false"
          >Cancel</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            :disabled="loader.state.isActive"
            @click="uploadFile()"
          >Upload</v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

    <odoo-supplier-dialog
      v-model="odooDialogRef"
      :suppliers="[model]"
    ></odoo-supplier-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from 'vue'
import FileDropInput from '@/components/FileDropInput.vue'
import { readFileAsDataUrl } from '@/utils'
import OdooSupplierDialog from '@/components/OdooSupplierDialog.vue'

export default {
  components: {
    FileDropInput,
    OdooSupplierDialog,
  },
  setup() {
    const api = inject('api')
    const loader = inject('loader')

    const itemCollection = api.collection('suppliers', {
      params: {
      },
      filter: {
        query: 'query:name',
      },
      store: true,
    })

    const { items, filter, totalCount, options, isSearching } = itemCollection

    const headers = [
      { text: 'Code', value: 'code' },
      { text: 'Name', value: 'name' },
      { text: 'Odoo name', value: 'odoo_name' },
    ]

    const dialog = ref(false)
    const uploadDialog = ref(false)
    const odooDialogRef = ref(false)

    const itemModel = api.model('suppliers/:id', {
      params: {
      },
      afterFind: model => {
        model.setAttribute('fields_data', model.getJsonAttribute('fields') || [])
      },
      beforeSave: model => {
        model.setJsonAttribute('fields', model.getAttribute('fields_data', []))
      },
    })
    const { attributes: model, errors, isBusy, isNewRecord } = itemModel

    loader.bindRef(isBusy)

    function newClick() {
      itemModel.clear()
      dialog.value = true
    }

    function rowClick(item) {
      itemModel.find(item.id).then(() => {
        dialog.value = true
      })
    }

    function saveClick() {
      itemModel.save().then(() => {
        itemCollection.search()
        dialog.value = false
      })
    }

    const importModel = api.model('product-variant-suppliers/import', {
      attributes: {
        total_rows: 0,
        total_updated: 0,
        message: null,
      },
    })
    const { attributes: uploadModel, errors: uploadErrors } = importModel

    const file = ref()


    function showUploadDialog() {
      importModel.clear().setAttributes({ csv_separator: ';', supplier_id: itemModel.getAttribute('id') })
      file.value = null
      uploadDialog.value = true
    }

    function uploadFile() {
      loader.show()
      readFileAsDataUrl(file.value).then(data => {
        importModel.setAttributes({ data_url: data })
      }).catch(() => {
        importModel.setAttributes({ data_url: null })
      }).finally(() => {
        importModel.create().then(() => {
          uploadDialog.value = false
          importModel.setAttribute('message', importModel.getAttribute('total_updated') + '/' + importModel.getAttribute('total_rows') + ' prices updated')
          setTimeout(() => {
            importModel.setAttribute('message', null)
          }, 6000)
        }).finally(() => {
          loader.hide()
        })
      })
    }


    const exportModel = api.model('suppliers/export', {
      attributes: {
        supplier_ids: [],
      },
    })

    function downloadExport() {
      loader.show()
      exportModel.setAttribute('supplier_ids', [itemModel.getAttribute('id')])
      exportModel.create().then(() => {
        const url = exportModel.getAttribute('data_url')
        const link = document.createElement('a')
        link.href = url
        link.download = 'ProductExport.zip'
        link.click()
      }).finally(() => {
        loader.hide()
      })
    }

    const pricesModel = api.model('suppliers/prices', {
      attributes: {
        supplier_ids: [],
      },
    })

    function downloadPrices() {
      loader.show()
      pricesModel.setAttribute('supplier_ids', [itemModel.getAttribute('id')])
      pricesModel.create().then(() => {
        const url = pricesModel.getAttribute('data_url')
        const link = document.createElement('a')
        link.href = url
        link.download = 'ProductPrices.csv'
        link.click()
      }).finally(() => {
        loader.hide()
      })
    }



    const searchKeyUp = itemCollection.debounceSearch(300)

    return {
      user: api.user,
      headers,
      items,
      filter,
      searchKeyUp,
      totalCount,
      options,
      isSearching,
      newClick,
      rowClick,
      saveClick,
      dialog,
      loader,

      file,
      showUploadDialog,
      uploadDialog,
      uploadFile,
      uploadModel,
      uploadErrors,
      model,
      errors,
      isNewRecord,

      downloadExport,
      downloadPrices,

      odooDialogRef,
    }

  },
}
</script>


<style scoped>

:deep(.v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

</style>
